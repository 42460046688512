$blue: #687bf7;
$lightblue: #72d9ef;
$cyan: #79e7eb;

.carousel-img {
  width: 100%;
  object-position: center;
  object-fit: cover;
}

section {
  max-width: 20em;
  margin: 10vh auto 0;
  background: whitesmoke;
  padding: 0.5em 1em;
  border-radius: 0.5em;
}

.drag-n-drop ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    cursor: move;
    line-height: 1;
    margin-bottom: 0.1em;
    border-radius: 5px;

    &.dropArea {
      color: white !important;
      background: white !important;
      position: relative;

      &::before {
        content: "Drop Here";
        color: $blue;
        font-size: 0.5em;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        border: 2px dashed $blue;
        border-radius: 3px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
      }

      span {
        display: none;
      }

      p {
        margin-left: 1em;
      }
    }

    &:hover {
      background: linear-gradient(to left, $blue, $lightblue);
      color: white;

      span {
        background-color: $cyan;
      }
    }

    span {
      display: inline-block;
      margin-right: 1em;
      margin-left: 0.5em;
      background-color: $lightblue;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: white;
      font-weight: bold;
      transition: background-color 100ms linear;
    }

    p {
      transition: margin-left 50ms ease-in-out;
    }

    i {
      margin-left: auto;
      margin-right: 1em;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
