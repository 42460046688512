.custom-create {
  padding: 1rem;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
