.custom-dropdown,
.custom-btn,
.boo {
  margin: 0.6rem;
}

.custom-create {
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.label {
  display: block;
  margin-bottom: 7px;
}

.custom-btn {
  display: inline-block;
  border: none;
  padding: 7px 20px;
  cursor: pointer;
  font-size: 1.2rem;
  background-color: #2196f3;
  color: #fff;
  border-radius: 4px;
}

.custom-btn:disabled {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(0, 0, 0, 0.7);
}

.custom-input {
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: inherit;
  color: rgba(0, 0, 0, 0.8);
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.flex-column {
  flex-flow: column;
}

.w-50 {
  width: 50%;
}

.single-date {
  margin: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  // background-color: #d8d8d8;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    .delete {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.active {
  opacity: 1;
  pointer-events: all;
}

.create {
  padding: 1rem;
  border-radius: 3px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 1.5rem;
  border: none;
  background-color: #0095ee;
  color: #fff;
}

.delete {
  color: red;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;
}
