.clamp1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.clamp2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.clamp3 {
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.clamp4 {
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; }


/*********************
  Media Query Classes
**********************/
@media only screen and (max-width: 600px) {
  .hide-on-small-only, .hide-on-small-and-down {
    display: none !important; } }

@media only screen and (max-width: 1023px) {
  .hide-on-med-and-down {
    display: none !important; } }

@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important; } }

@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .hide-on-med-only {
    display: none !important; } }

@media only screen and (min-width: 1024px) {
  .hide-on-large-only {
    display: none !important; } }

@media only screen and (min-width: 1024px) {
  .show-on-large {
    display: block !important; } }

@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .show-on-medium {
    display: block !important; } }

@media only screen and (max-width: 600px) {
  .show-on-small {
    display: block !important; } }

@media only screen and (min-width: 601px) {
  .show-on-medium-and-up {
    display: block !important; } }

@media only screen and (max-width: 1023px) {
  .show-on-medium-and-down {
    display: block !important; } }

@media only screen and (max-width: 600px) {
  .center-on-small-only {
    text-align: center; } }



.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%; }

@media only screen and (min-width: 601px) {
  .container {
    width: 85%; } }
@media only screen and (min-width: 1024px) {
  .container {
    width: 90%; } }
.container .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem; }

.section {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .section.no-pad {
    padding: 0; }
  .section.no-pad-bot {
    padding-bottom: 0; }
  .section.no-pad-top {
    padding-top: 0; }

.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px; }
  .row:after {
    content: "";
    display: table;
    clear: both; }
  .row .col {
    float: left;
    box-sizing: border-box;
    padding: 0 0.75rem;
    min-height: 1px; }
    .row .col[class*="push-"], .row .col[class*="pull-"] {
      position: relative; }
    .row .col.s1 {
      width: 4.16667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s2 {
      width: 8.33333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s3 {
      width: 12.5%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s4 {
      width: 16.66667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s5 {
      width: 20.83333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s6 {
      width: 25%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s7 {
      width: 29.16667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s8 {
      width: 33.33333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s9 {
      width: 37.5%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s10 {
      width: 41.66667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s11 {
      width: 45.83333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s12 {
      width: 50%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s13 {
      width: 54.16667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s14 {
      width: 58.33333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s15 {
      width: 62.5%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s16 {
      width: 66.66667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s17 {
      width: 70.83333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s18 {
      width: 75%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s19 {
      width: 79.16667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s20 {
      width: 83.33333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s21 {
      width: 87.5%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s22 {
      width: 91.66667%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s23 {
      width: 95.83333%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.s24 {
      width: 100%;
      margin-left: auto;
      left: auto;
      right: auto; }
    .row .col.offset-s1 {
      margin-left: 4.16667%; }
    .row .col.pull-s1 {
      right: 4.16667%; }
    .row .col.push-s1 {
      left: 4.16667%; }
    .row .col.offset-s2 {
      margin-left: 8.33333%; }
    .row .col.pull-s2 {
      right: 8.33333%; }
    .row .col.push-s2 {
      left: 8.33333%; }
    .row .col.offset-s3 {
      margin-left: 12.5%; }
    .row .col.pull-s3 {
      right: 12.5%; }
    .row .col.push-s3 {
      left: 12.5%; }
    .row .col.offset-s4 {
      margin-left: 16.66667%; }
    .row .col.pull-s4 {
      right: 16.66667%; }
    .row .col.push-s4 {
      left: 16.66667%; }
    .row .col.offset-s5 {
      margin-left: 20.83333%; }
    .row .col.pull-s5 {
      right: 20.83333%; }
    .row .col.push-s5 {
      left: 20.83333%; }
    .row .col.offset-s6 {
      margin-left: 25%; }
    .row .col.pull-s6 {
      right: 25%; }
    .row .col.push-s6 {
      left: 25%; }
    .row .col.offset-s7 {
      margin-left: 29.16667%; }
    .row .col.pull-s7 {
      right: 29.16667%; }
    .row .col.push-s7 {
      left: 29.16667%; }
    .row .col.offset-s8 {
      margin-left: 33.33333%; }
    .row .col.pull-s8 {
      right: 33.33333%; }
    .row .col.push-s8 {
      left: 33.33333%; }
    .row .col.offset-s9 {
      margin-left: 37.5%; }
    .row .col.pull-s9 {
      right: 37.5%; }
    .row .col.push-s9 {
      left: 37.5%; }
    .row .col.offset-s10 {
      margin-left: 41.66667%; }
    .row .col.pull-s10 {
      right: 41.66667%; }
    .row .col.push-s10 {
      left: 41.66667%; }
    .row .col.offset-s11 {
      margin-left: 45.83333%; }
    .row .col.pull-s11 {
      right: 45.83333%; }
    .row .col.push-s11 {
      left: 45.83333%; }
    .row .col.offset-s12 {
      margin-left: 50%; }
    .row .col.pull-s12 {
      right: 50%; }
    .row .col.push-s12 {
      left: 50%; }
    .row .col.offset-s13 {
      margin-left: 54.16667%; }
    .row .col.pull-s13 {
      right: 54.16667%; }
    .row .col.push-s13 {
      left: 54.16667%; }
    .row .col.offset-s14 {
      margin-left: 58.33333%; }
    .row .col.pull-s14 {
      right: 58.33333%; }
    .row .col.push-s14 {
      left: 58.33333%; }
    .row .col.offset-s15 {
      margin-left: 62.5%; }
    .row .col.pull-s15 {
      right: 62.5%; }
    .row .col.push-s15 {
      left: 62.5%; }
    .row .col.offset-s16 {
      margin-left: 66.66667%; }
    .row .col.pull-s16 {
      right: 66.66667%; }
    .row .col.push-s16 {
      left: 66.66667%; }
    .row .col.offset-s17 {
      margin-left: 70.83333%; }
    .row .col.pull-s17 {
      right: 70.83333%; }
    .row .col.push-s17 {
      left: 70.83333%; }
    .row .col.offset-s18 {
      margin-left: 75%; }
    .row .col.pull-s18 {
      right: 75%; }
    .row .col.push-s18 {
      left: 75%; }
    .row .col.offset-s19 {
      margin-left: 79.16667%; }
    .row .col.pull-s19 {
      right: 79.16667%; }
    .row .col.push-s19 {
      left: 79.16667%; }
    .row .col.offset-s20 {
      margin-left: 83.33333%; }
    .row .col.pull-s20 {
      right: 83.33333%; }
    .row .col.push-s20 {
      left: 83.33333%; }
    .row .col.offset-s21 {
      margin-left: 87.5%; }
    .row .col.pull-s21 {
      right: 87.5%; }
    .row .col.push-s21 {
      left: 87.5%; }
    .row .col.offset-s22 {
      margin-left: 91.66667%; }
    .row .col.pull-s22 {
      right: 91.66667%; }
    .row .col.push-s22 {
      left: 91.66667%; }
    .row .col.offset-s23 {
      margin-left: 95.83333%; }
    .row .col.pull-s23 {
      right: 95.83333%; }
    .row .col.push-s23 {
      left: 95.83333%; }
    .row .col.offset-s24 {
      margin-left: 100%; }
    .row .col.pull-s24 {
      right: 100%; }
    .row .col.push-s24 {
      left: 100%; }
    @media only screen and (min-width: 601px) {
      .row .col.m1 {
        width: 4.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m2 {
        width: 8.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m3 {
        width: 12.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m4 {
        width: 16.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m5 {
        width: 20.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m6 {
        width: 25%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m7 {
        width: 29.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m8 {
        width: 33.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m9 {
        width: 37.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m10 {
        width: 41.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m11 {
        width: 45.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m12 {
        width: 50%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m13 {
        width: 54.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m14 {
        width: 58.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m15 {
        width: 62.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m16 {
        width: 66.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m17 {
        width: 70.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m18 {
        width: 75%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m19 {
        width: 79.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m20 {
        width: 83.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m21 {
        width: 87.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m22 {
        width: 91.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m23 {
        width: 95.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.m24 {
        width: 100%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.offset-m1 {
        margin-left: 4.16667%; }
      .row .col.pull-m1 {
        right: 4.16667%; }
      .row .col.push-m1 {
        left: 4.16667%; }
      .row .col.offset-m2 {
        margin-left: 8.33333%; }
      .row .col.pull-m2 {
        right: 8.33333%; }
      .row .col.push-m2 {
        left: 8.33333%; }
      .row .col.offset-m3 {
        margin-left: 12.5%; }
      .row .col.pull-m3 {
        right: 12.5%; }
      .row .col.push-m3 {
        left: 12.5%; }
      .row .col.offset-m4 {
        margin-left: 16.66667%; }
      .row .col.pull-m4 {
        right: 16.66667%; }
      .row .col.push-m4 {
        left: 16.66667%; }
      .row .col.offset-m5 {
        margin-left: 20.83333%; }
      .row .col.pull-m5 {
        right: 20.83333%; }
      .row .col.push-m5 {
        left: 20.83333%; }
      .row .col.offset-m6 {
        margin-left: 25%; }
      .row .col.pull-m6 {
        right: 25%; }
      .row .col.push-m6 {
        left: 25%; }
      .row .col.offset-m7 {
        margin-left: 29.16667%; }
      .row .col.pull-m7 {
        right: 29.16667%; }
      .row .col.push-m7 {
        left: 29.16667%; }
      .row .col.offset-m8 {
        margin-left: 33.33333%; }
      .row .col.pull-m8 {
        right: 33.33333%; }
      .row .col.push-m8 {
        left: 33.33333%; }
      .row .col.offset-m9 {
        margin-left: 37.5%; }
      .row .col.pull-m9 {
        right: 37.5%; }
      .row .col.push-m9 {
        left: 37.5%; }
      .row .col.offset-m10 {
        margin-left: 41.66667%; }
      .row .col.pull-m10 {
        right: 41.66667%; }
      .row .col.push-m10 {
        left: 41.66667%; }
      .row .col.offset-m11 {
        margin-left: 45.83333%; }
      .row .col.pull-m11 {
        right: 45.83333%; }
      .row .col.push-m11 {
        left: 45.83333%; }
      .row .col.offset-m12 {
        margin-left: 50%; }
      .row .col.pull-m12 {
        right: 50%; }
      .row .col.push-m12 {
        left: 50%; }
      .row .col.offset-m13 {
        margin-left: 54.16667%; }
      .row .col.pull-m13 {
        right: 54.16667%; }
      .row .col.push-m13 {
        left: 54.16667%; }
      .row .col.offset-m14 {
        margin-left: 58.33333%; }
      .row .col.pull-m14 {
        right: 58.33333%; }
      .row .col.push-m14 {
        left: 58.33333%; }
      .row .col.offset-m15 {
        margin-left: 62.5%; }
      .row .col.pull-m15 {
        right: 62.5%; }
      .row .col.push-m15 {
        left: 62.5%; }
      .row .col.offset-m16 {
        margin-left: 66.66667%; }
      .row .col.pull-m16 {
        right: 66.66667%; }
      .row .col.push-m16 {
        left: 66.66667%; }
      .row .col.offset-m17 {
        margin-left: 70.83333%; }
      .row .col.pull-m17 {
        right: 70.83333%; }
      .row .col.push-m17 {
        left: 70.83333%; }
      .row .col.offset-m18 {
        margin-left: 75%; }
      .row .col.pull-m18 {
        right: 75%; }
      .row .col.push-m18 {
        left: 75%; }
      .row .col.offset-m19 {
        margin-left: 79.16667%; }
      .row .col.pull-m19 {
        right: 79.16667%; }
      .row .col.push-m19 {
        left: 79.16667%; }
      .row .col.offset-m20 {
        margin-left: 83.33333%; }
      .row .col.pull-m20 {
        right: 83.33333%; }
      .row .col.push-m20 {
        left: 83.33333%; }
      .row .col.offset-m21 {
        margin-left: 87.5%; }
      .row .col.pull-m21 {
        right: 87.5%; }
      .row .col.push-m21 {
        left: 87.5%; }
      .row .col.offset-m22 {
        margin-left: 91.66667%; }
      .row .col.pull-m22 {
        right: 91.66667%; }
      .row .col.push-m22 {
        left: 91.66667%; }
      .row .col.offset-m23 {
        margin-left: 95.83333%; }
      .row .col.pull-m23 {
        right: 95.83333%; }
      .row .col.push-m23 {
        left: 95.83333%; }
      .row .col.offset-m24 {
        margin-left: 100%; }
      .row .col.pull-m24 {
        right: 100%; }
      .row .col.push-m24 {
        left: 100%; } }
    @media only screen and (min-width: 1024px) {
      .row .col.l1 {
        width: 4.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l2 {
        width: 8.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l3 {
        width: 12.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l4 {
        width: 16.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l5 {
        width: 20.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l6 {
        width: 25%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l7 {
        width: 29.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l8 {
        width: 33.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l9 {
        width: 37.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l10 {
        width: 41.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l11 {
        width: 45.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l12 {
        width: 50%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l13 {
        width: 54.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l14 {
        width: 58.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l15 {
        width: 62.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l16 {
        width: 66.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l17 {
        width: 70.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l18 {
        width: 75%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l19 {
        width: 79.16667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l20 {
        width: 83.33333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l21 {
        width: 87.5%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l22 {
        width: 91.66667%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l23 {
        width: 95.83333%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.l24 {
        width: 100%;
        margin-left: auto;
        left: auto;
        right: auto; }
      .row .col.offset-l1 {
        margin-left: 4.16667%; }
      .row .col.pull-l1 {
        right: 4.16667%; }
      .row .col.push-l1 {
        left: 4.16667%; }
      .row .col.offset-l2 {
        margin-left: 8.33333%; }
      .row .col.pull-l2 {
        right: 8.33333%; }
      .row .col.push-l2 {
        left: 8.33333%; }
      .row .col.offset-l3 {
        margin-left: 12.5%; }
      .row .col.pull-l3 {
        right: 12.5%; }
      .row .col.push-l3 {
        left: 12.5%; }
      .row .col.offset-l4 {
        margin-left: 16.66667%; }
      .row .col.pull-l4 {
        right: 16.66667%; }
      .row .col.push-l4 {
        left: 16.66667%; }
      .row .col.offset-l5 {
        margin-left: 20.83333%; }
      .row .col.pull-l5 {
        right: 20.83333%; }
      .row .col.push-l5 {
        left: 20.83333%; }
      .row .col.offset-l6 {
        margin-left: 25%; }
      .row .col.pull-l6 {
        right: 25%; }
      .row .col.push-l6 {
        left: 25%; }
      .row .col.offset-l7 {
        margin-left: 29.16667%; }
      .row .col.pull-l7 {
        right: 29.16667%; }
      .row .col.push-l7 {
        left: 29.16667%; }
      .row .col.offset-l8 {
        margin-left: 33.33333%; }
      .row .col.pull-l8 {
        right: 33.33333%; }
      .row .col.push-l8 {
        left: 33.33333%; }
      .row .col.offset-l9 {
        margin-left: 37.5%; }
      .row .col.pull-l9 {
        right: 37.5%; }
      .row .col.push-l9 {
        left: 37.5%; }
      .row .col.offset-l10 {
        margin-left: 41.66667%; }
      .row .col.pull-l10 {
        right: 41.66667%; }
      .row .col.push-l10 {
        left: 41.66667%; }
      .row .col.offset-l11 {
        margin-left: 45.83333%; }
      .row .col.pull-l11 {
        right: 45.83333%; }
      .row .col.push-l11 {
        left: 45.83333%; }
      .row .col.offset-l12 {
        margin-left: 50%; }
      .row .col.pull-l12 {
        right: 50%; }
      .row .col.push-l12 {
        left: 50%; }
      .row .col.offset-l13 {
        margin-left: 54.16667%; }
      .row .col.pull-l13 {
        right: 54.16667%; }
      .row .col.push-l13 {
        left: 54.16667%; }
      .row .col.offset-l14 {
        margin-left: 58.33333%; }
      .row .col.pull-l14 {
        right: 58.33333%; }
      .row .col.push-l14 {
        left: 58.33333%; }
      .row .col.offset-l15 {
        margin-left: 62.5%; }
      .row .col.pull-l15 {
        right: 62.5%; }
      .row .col.push-l15 {
        left: 62.5%; }
      .row .col.offset-l16 {
        margin-left: 66.66667%; }
      .row .col.pull-l16 {
        right: 66.66667%; }
      .row .col.push-l16 {
        left: 66.66667%; }
      .row .col.offset-l17 {
        margin-left: 70.83333%; }
      .row .col.pull-l17 {
        right: 70.83333%; }
      .row .col.push-l17 {
        left: 70.83333%; }
      .row .col.offset-l18 {
        margin-left: 75%; }
      .row .col.pull-l18 {
        right: 75%; }
      .row .col.push-l18 {
        left: 75%; }
      .row .col.offset-l19 {
        margin-left: 79.16667%; }
      .row .col.pull-l19 {
        right: 79.16667%; }
      .row .col.push-l19 {
        left: 79.16667%; }
      .row .col.offset-l20 {
        margin-left: 83.33333%; }
      .row .col.pull-l20 {
        right: 83.33333%; }
      .row .col.push-l20 {
        left: 83.33333%; }
      .row .col.offset-l21 {
        margin-left: 87.5%; }
      .row .col.pull-l21 {
        right: 87.5%; }
      .row .col.push-l21 {
        left: 87.5%; }
      .row .col.offset-l22 {
        margin-left: 91.66667%; }
      .row .col.pull-l22 {
        right: 91.66667%; }
      .row .col.push-l22 {
        left: 91.66667%; }
      .row .col.offset-l23 {
        margin-left: 95.83333%; }
      .row .col.pull-l23 {
        right: 95.83333%; }
      .row .col.push-l23 {
        left: 95.83333%; }
      .row .col.offset-l24 {
        margin-left: 100%; }
      .row .col.pull-l24 {
        right: 100%; }
      .row .col.push-l24 {
        left: 100%; } }