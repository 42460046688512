.chart {
  margin: 1.5rem;
}

.buttons {
  button {
    padding: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    // background-color: #2196f3;
    background-color: #76c2ff;
    color: #fff;
    font-size: 1rem;
  }

  .active {
    background-color: #2196f3;
  }

  .btn__year {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .btn__month {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.cron {
  &__btn {
    border: none;
    font-size: 1.2rem;
    background-color: #2196f3;
    display: inline-block;
    color: #fff;
    padding: 3px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #1c76bd;
    }
  }
}

.dashboard {
  .d-flex {
    flex-wrap: wrap;
  }

  &__boxes {
    display: flex;
  }

  &__box {
    margin-right: 2rem;
    padding: 1rem;
    height: 9rem;
    width: 9rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    .item--1 {
      word-wrap: break-word;
      text-align: center;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.9);
    }
    .item--2 {
      margin-top: 0.5rem;
      font-size: 1.5rem;
      color: #2196f3;
    }
  }
}
